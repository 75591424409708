import React, { useEffect, useState } from "react"
import FacebookLogin from "react-facebook-login"
import axios from "axios"
import AdminLayout from "../../layouts/AdminLayout"
import { Grid } from "@material-ui/core"

const MyComponent = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [posts, setPosts] = useState([])

  const responseFacebook = response => {
    if (response.error) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("user_id")
      return setLoggedIn(false)
    } else {
      localStorage.setItem("access_token", response.accessToken)
      localStorage.setItem("user_id", response.userID)
      setLoggedIn(true)

      getFacebookFeed()
    }
  }

  const getFacebookFeed = () => {
    let access_token = localStorage.getItem("access_token")
    axios
      .get(
        `https://graph.facebook.com/v8.0/me?fields=posts{message,message_tags,attachments,created_time}&access_token=${access_token}`
      )
      .then(({ data }) => {
        console.log(data)
        setPosts(data.posts ? data.posts.data : [])
      })
      .catch(err => {
        console.log(err)
        localStorage.removeItem("access_token")
        localStorage.removeItem("user_id")
        return setLoggedIn(false)
      })
  }

  useEffect(() => {
    let isFaceBookLoggedIn = localStorage.getItem("access_token")
    if (isFaceBookLoggedIn) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [loggedIn])

  return (
    <AdminLayout>
      {!loggedIn ? (
        <FacebookLogin
          appId="1467560603427674"
          autoLoad={true}
          fields="name,email,picture"
          scope="public_profile,user_posts"
          callback={responseFacebook}
        />
      ) : (
        <Grid align="center">
          <Grid item xs={6} style={{}}>
            {posts.map(post => {
              return (
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={5} xl={5} sm={12} md={12}>
                    <img
                      width={"100%"}
                      src={post.attachments.data[0].media.image.src}
                    />
                  </Grid>
                  <Grid item xs={12} xs={12} lg={7} xl={7} sm={12} md={12}>
                    <p
                      style={{
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      {post.message}
                    </p>
                    <p
                      style={{
                        textAlign: "left",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      {new Date(post.created_time).toLocaleString()}
                    </p>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      )}
    </AdminLayout>
  )
}

export default MyComponent
