import React from "react"
import Header from "../components/AdminHeader"

const AdminLayout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <div
        style={{
          position: "absolute",
          overflowY: "auto",
          overflowX: "hidden",
          height: "80vh",
          width: "100vw",
        }}
      >
        {children}
      </div>
    </React.Fragment>
  )
}

export default AdminLayout
