import React, { useEffect } from "react"
import { Home } from "@material-ui/icons"
import { navigate } from "gatsby"

const AdminHeader = () => {
  const _logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("username")
    localStorage.removeItem("role")
    localStorage.removeItem("user")
    navigate("/admin/login")
  }

  useEffect(() => {
    var isLoggedIn = localStorage.getItem("token")
    if (!isLoggedIn) {
      localStorage.removeItem("token")
      localStorage.removeItem("username")
      localStorage.removeItem("role")
      localStorage.removeItem("user")
      navigate("/admin/login")
    }
  }, [])

  return (
    <div style={{ height: "14vh", padding: 10 }}>
      <img src={require("../../assets/Logo/logo.png")} width={220} />

      <div style={{ float: "right", padding: 20, display: "inline" }}>
        {/* <button
          type="button"
          className="btn btn-outline-primary"
          style={{ marginLeft: 10 }}
        >
          Facebook feed
        </button> */}
        <button
          type="button"
          className="btn btn-danger"
          style={{ marginLeft: 10, backgroundColor: "#E51C21" }}
          onClick={_logout}
        >
          Logout
        </button>
      </div>
      {/* <div
        style={{ float: "right", padding: 20, marginTop: 6, display: "inline" }}
      >
        <Home style={{ marginLeft: 10, color: "#6AA5E3" }} />
      </div> */}
    </div>
  )
}

export default AdminHeader
